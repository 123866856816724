import React from 'react';
import Image from 'next/image';
import parse, { HTMLReactParserOptions } from 'html-react-parser';
import { Grid } from '@mantine/core';
import * as Types from 'declarations';
import TrekCard from 'components/TrekCard';
import { homePageTripToTripCard } from 'lib/helper';
import { getUploadThumbPath } from 'lib/display';
import TripMapComponent from 'components/HomePage/TripMap';
const TripCards = ({
  tripIds,
  trips
}: {
  tripIds: number[];
  trips: Types.App.HomePageTrip[];
}) => {
  const filteredTreks = trips.filter(trip => tripIds.includes(trip.id));
  return <>
			<div className="trek-card-container article-trek-card-container">
				{filteredTreks.map((trek, key) => <TrekCard tripCardProps={homePageTripToTripCard(trek)} key={key} />)}
			</div>
		</>;
};
const TripMap = ({
  // tripIds,
  trips
}: {
  tripIds: number[];
  trips: Types.App.HomePageTrip[];
}) => {
  const locations = trips.map(trip => ({
    latitude: trip.routeLocation.latitude,
    longitude: trip.routeLocation.longitude,
    height: trip.height
  }));
  return <div style={{
    maxWidth: '100%'
  }} data-sentry-component="TripMap" data-sentry-source-file="HTMLContent.tsx">
			<TripMapComponent locations={locations} version={Types.VERSION.PEAKS} data-sentry-element="TripMapComponent" data-sentry-source-file="HTMLContent.tsx" />
		</div>;
};
const ImageGallery = ({
  imageIds
}: {
  imageIds: number[];
}) => {
  // row size?
  const images = imageIds.filter((img, idx) => idx < 4);
  const imageCount = images.length;
  const rowSize = 12 / imageCount;
  const thumbSize = imageCount > 2 ? 'medium' : 'large';
  return <Grid data-sentry-element="Grid" data-sentry-component="ImageGallery" data-sentry-source-file="HTMLContent.tsx">
			{images.map((imgId, idx) => <Grid.Col key={idx} span={rowSize} style={{
      padding: 4
    }}>
					<Image src={getUploadThumbPath(imgId, thumbSize)} width={500} // Set a fixed width just for validation
      height={500} // Set a fixed width just for validation
      style={{
        borderRadius: 8,
        objectFit: 'cover',
        width: '100%',
        height: '100%'
      }} alt="" />
				</Grid.Col>)}
		</Grid>;
};
const HTMLContent = ({
  html,
  id,
  className,
  trips,
  version
}: {
  html: string;
  id?: string;
  className?: string;
  trips?: Types.App.HomePageTrip[];
  version?: Types.VERSION;
}) => {
  const options: HTMLReactParserOptions = {
    replace: domNode => {
      if (domNode.type === 'tag' && domNode.name === 'img') {
        return <Image src={domNode.attribs.src} alt={domNode.attribs.alt || !!version ? `${version === Types.VERSION.PEAKS ? 'Guided Peaks' : 'Guided Trekking'}` : ''} width={500} height={500} layout="responsive" className="article-image-replaced" />;
      }
      if (domNode.type === 'tag' && domNode.attribs['data-shortcode']) {
        const shortcode = domNode.attribs['data-shortcode'];
        const dataIds = domNode.attribs['data-ids'].split(',').map(Number);
        const relevantTrips = (trips ?? []).filter(trip => dataIds.includes(trip.id));
        if (shortcode === 'tripcards') {
          return <TripCards tripIds={dataIds} trips={relevantTrips ?? []} />;
        } else if (shortcode === 'tripmap') {
          return <TripMap tripIds={dataIds} trips={relevantTrips ?? []} />;
        } else if (shortcode === 'image_gallery') {
          return <ImageGallery imageIds={dataIds} />;
        }
      }
    }
  };
  return <div id={id} className={className} data-sentry-component="HTMLContent" data-sentry-source-file="HTMLContent.tsx">
			{parse(html, options)}
		</div>;
};
export default HTMLContent;