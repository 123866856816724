import Link from 'next/link';
import * as Redux from 'react-redux';
import { Mountain, Shoe, Stretching } from 'tabler-icons-react';
import * as Types from '../declarations';
import { distanceOrHeightNew, formatDifficulty, formatNumberOrRange, getColorByHeight, getTrekRoute, getUploadThumbPath } from 'lib/display';
import { getFlagURL } from '../lib/helper';
import classNames from 'classnames';
import Image from 'next/image';
// @ts-ignore
import iso3311a2 from 'iso-country-lookup';
interface Props {
  tripCardProps: Types.App.TripCardProps;
  overloadPrice?: {
    price: number;
    currency: string;
  };
}
const unitSelector = (state: Types.AppState) => state.unit;
const TrekCard = ({
  tripCardProps,
  overloadPrice
}: Props) => {
  const {
    name,
    trekOrClimb,
    continent,
    countries,
    slug,
    lowestCost,
    totalDistanceLength,
    totalDaysLength,
    height,
    firstUploadId,
    climbDifficulty,
    trekDifficulty,
    isValidCompletePage
  } = tripCardProps;
  const url = getUploadThumbPath(firstUploadId, 'medium');
  const isTrekNotPeak = trekOrClimb === 'TREK';
  const trekRoute = getTrekRoute(trekOrClimb, continent, countries, slug);
  const unit = Redux.useSelector(unitSelector);
  const distanceOrHeight = distanceOrHeightNew(totalDistanceLength, height, unit, trekOrClimb);
  const lengthInDays = totalDaysLength ? formatNumberOrRange(totalDaysLength) : undefined;
  const difficulty = trekOrClimb === Types.App.DataTypes.TrekOrClimb.TREK ? !!trekDifficulty ? `${Math.floor(trekDifficulty)} / 10` : undefined : (climbDifficulty ?? []).length > 0 ? formatDifficulty(climbDifficulty /*, 'french?'*/) : undefined;
  const formattedPrice = !!overloadPrice ? `${overloadPrice.currency === 'USD' ? '$' : overloadPrice.currency === 'EUR' ? '€' : overloadPrice.currency === 'GBP' ? '£' : `${overloadPrice.currency}`}${overloadPrice.price.toLocaleString()}` : !!lowestCost ? `$${Number(lowestCost).toLocaleString()}` : undefined;
  return <div className={classNames({
    'trek-card': true,
    'is-holding-page-only': !isValidCompletePage
  })} data-sentry-component="TrekCard" data-sentry-source-file="TrekCard.tsx">
			<Link href={trekRoute} legacyBehavior data-sentry-element="Link" data-sentry-source-file="TrekCard.tsx">
				<a className="blank-link">
					<Image src={url} alt={`${trekOrClimb ? 'climm' : 'trek'} ${name}`} layout="fill" objectFit="cover" quality={100} style={{
          borderRadius: 4
        }} data-sentry-element="Image" data-sentry-source-file="TrekCard.tsx" />
					<div className={'name text-shadow'}>
						{name}
						{/* {!isTrekNotPeak && (
                            <>
                                &nbsp;
                                <i
                                    className="denote-mountain-icon fas fa-mountain"
                                    aria-hidden
                                ></i>
                            </>
                        )} */}
					</div>
				</a>
			</Link>
			{difficulty && <div className={'text-shadow small-detail top-left'}>
					<span>
						<Stretching size="18px" />
						&nbsp;{difficulty}{' '}
					</span>
				</div>}

			{isTrekNotPeak && lengthInDays && <div className={'text-shadow small-detail bottom-right'}>
					<span>{lengthInDays} days</span>
				</div>}
			{!isTrekNotPeak && countries.length > 0 && <div className={'text-shadow small-detail bottom-right'}>
					<span>
						{countries.map((isoCode: string, countryIndex: number) => <span key={countryIndex} className="country">
								<Image title={iso3311a2.getCountry(isoCode.toUpperCase())} alt={iso3311a2.getCountry(isoCode.toUpperCase())} src={getFlagURL(isoCode)} className="flag" width={27} height={18} />
							</span>)}
					</span>
				</div>}

			{formattedPrice && <div className={'text-shadow small-detail top-right'}>
					<span>{formattedPrice}</span>
				</div>}

			{distanceOrHeight && <div className={'text-shadow small-detail bottom-left'}>
					<span>
						{isTrekNotPeak ? <Shoe size="18px" /> : <Mountain size="18px" color={getColorByHeight(height as number)} />}
						&nbsp;
						{distanceOrHeight}
					</span>
				</div>}
		</div>;
};
export default TrekCard;