import React, { ReactNode } from 'react';
import { Button } from '@mantine/core';
interface GenericProps<T> {
  label?: ReactNode;
  items: {
    display: string | ReactNode;
    value: T;
  }[];
  value?: T;
  nullable?: boolean;
  onChange: (unit: T) => void;
  size?: string;
}
const RadioButtonGroup = <T extends string | number | boolean,>({
  label,
  items,
  value,
  nullable = true,
  onChange,
  size = 'sm'
}: GenericProps<T | undefined>) => {
  return <div className="toggle-filter-control" data-sentry-component="RadioButtonGroup" data-sentry-source-file="RadioButtonGroup.tsx">
			{label && <div className="label">
					{label}
					<br />
				</div>}
			<Button.Group data-sentry-element="unknown" data-sentry-source-file="RadioButtonGroup.tsx">
				{items.map((option, index) => <Button key={index} size={size} radius="sm"
      // color={value === option.value ? 'yellow' : 'gray'}
      variant={value === option.value ? 'filled' : 'default'} onClick={() => onChange(
      // set value to new option, unless it's the same as last option in which case unset it - unless not nullable then just leave it
      value === option.value ? nullable ? undefined : value : option.value)} style={{
        ...{
          color: value === option.value ? 'brown' : undefined
        }
      }}>
						<>{option.display}</>
					</Button>)}
			</Button.Group>
		</div>;
};
export default RadioButtonGroup;