import Image from 'next/image';
import * as Types from 'declarations';
import { ACCENT_COLOUR } from 'pages/_app';
interface Props {
  locations: {
    latitude: number;
    longitude: number;
    height: number | null;
  }[];
  version: Types.VERSION;
}
const TripMap = ({
  locations,
  version
}: Props) => {
  if (!locations) return <></>;
  const locationCoords = locations.map(({
    latitude,
    longitude
  }) => [longitude, latitude]);
  const jsonObject = {
    type: 'Feature',
    properties: {
      'marker-size': 's',
      'marker-color': ACCENT_COLOUR
    },
    geometry: {
      type: 'MultiPoint',
      coordinates: locationCoords
    }
  };
  const geojson = encodeURIComponent(JSON.stringify(jsonObject));
  const url = `https://api.mapbox.com/styles/v1/mapbox/streets-v12/static/geojson(${geojson})/auto/750x450?access_token=pk.eyJ1IjoiZzRmZDVnNGY1ZDRnNmQiLCJhIjoiY2tqY3dkNHNkMHlyMjMyc2NhMndqd29kOSJ9.8T_Vtjj5pL-wxSRv4IVuXA`;
  const urlLen = url.length;
  if (urlLen > 8192) {
    console.error('CUSTOM MAPBOX URL TOO LONG');
  }
  return <div data-sentry-component="TripMap" data-sentry-source-file="TripMap.tsx">
			<Image className="trip-map" src={url} alt={`Guided ${version === Types.VERSION.PEAKS ? 'Mountain climbing' : 'Trekking'} around the world - Nepal, Pakistan, Himalaya, South America`} width={750} height={450} data-sentry-element="Image" data-sentry-source-file="TripMap.tsx" />
		</div>;
};
export default TripMap;