// export {}
import { Text } from '@mantine/core';
import { Carousel } from '@mantine/carousel';
// import { useMediaQuery } from '@mantine/hooks'
import Link from 'next/link';
import { Paper, Title, Button } from '@mantine/core';
import Image from 'next/image';
import * as Types from 'declarations';
import { getArticleRoute, getUploadThumbPath } from 'lib/display';
interface CardProps {
  imageURL: string;
  title: string;
  slug: string;
  // category: string
}
const Card = ({
  imageURL,
  title,
  slug
}: CardProps) => {
  return <Paper shadow="md" p="xl" radius="md" className={'card'} style={{
    position: 'relative',
    overflow: 'hidden'
  }} data-sentry-element="Paper" data-sentry-component="Card" data-sentry-source-file="ArticlesCarousel.tsx">
			<Image src={imageURL} alt={title} layout="fill" objectFit="fill"
    // style={{
    // 	objectFit: 'cover',
    // }}
    className="image-class" data-sentry-element="Image" data-sentry-source-file="ArticlesCarousel.tsx" />
			<Title order={3} className={'title'} style={{
      zIndex: 100
    }} data-sentry-element="Title" data-sentry-source-file="ArticlesCarousel.tsx">
				<Text lineClamp={6} style={{
        lineHeight: '125%'
      }} data-sentry-element="Text" data-sentry-source-file="ArticlesCarousel.tsx">
					{title}
				</Text>
			</Title>
			<Link href={getArticleRoute({
      slug: slug
    } as Types.API.Article)} data-sentry-element="Link" data-sentry-source-file="ArticlesCarousel.tsx">
				<Button variant="white" color="dark" data-sentry-element="Button" data-sentry-source-file="ArticlesCarousel.tsx">
					Read article
				</Button>
			</Link>
		</Paper>;
};
const CardsCarousel = ({
  articleSummaries
}: {
  articleSummaries: {
    title: string;
    slug: string;
    imageId: number;
  }[];
}) => {
  // const theme = useMantineTheme()
  // const mobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`)
  const slides = articleSummaries.map(item => <Carousel.Slide key={item.title}>
			<Card
    // imageURL={getUploadThumbPath(item.imageId, 'large')}
    // imageURL={`https://www.guidedpeaks.com/cdn-cgi/image/format=auto,width=157,height=220,quality=100/${getUploadThumbPath(
    // 	item.imageId,
    // 	'large',
    // )}`}
    // imageURL={`https://${process.env.NEXT_PUBLIC_CDN_BASE}.cdn.guidedpeaks.com/originals/${item.imageId}.jpg`}
    // title={item.title}
    // slug={item.slug}
    imageURL={`https://www.guidedpeaks.com/cdn-cgi/image/format=auto,fit=cover,width=314,height=440,quality=100/${`https://${process.env.NEXT_PUBLIC_CDN_BASE}.cdn.guidedpeaks.com/originals/${item.imageId}.jpg`}`} title={item.title} slug={item.slug} />
		</Carousel.Slide>);
  return <div className="articles-carousel" data-sentry-component="CardsCarousel" data-sentry-source-file="ArticlesCarousel.tsx">
			<Carousel slideSize="30%" slideGap="md" align="start" breakpoints={[{
      maxWidth: 'xl',
      slideSize: '30%'
    }, {
      maxWidth: 'lg',
      slideSize: '30%'
    }, {
      maxWidth: 'md',
      slideSize: '43%'
    }, {
      maxWidth: 'sm',
      slideSize: '80%'
    }, {
      maxWidth: 'xs',
      slideSize: '100%'
    }]} previousControlLabel={'Previous'} nextControlLabel={'Next'} data-sentry-element="Carousel" data-sentry-source-file="ArticlesCarousel.tsx">
				{slides}
			</Carousel>
		</div>;
};
export default CardsCarousel;