import * as React from 'react';
import { Alert, Button, Group, Loader, Modal, Textarea, TextInput } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useForm } from '@mantine/form';
import { AlertCircle, Check, Mail } from 'tabler-icons-react';
import { useMutation } from '@apollo/client';
import { useRouter } from 'next/router';
import * as Types from 'declarations';
import * as GQLQueries from '../lib/queries';
const FeedbackWidget = ({
  version
}: {
  version: Types.VERSION;
}) => {
  const [opened, {
    open,
    close
  }] = useDisclosure(false);
  const [submitFeedback, {
    loading: isSubmitting
  }] = useMutation(GQLQueries.Mutations.SubmitFeedback);
  const [isSuccess, setIsSuccess] = React.useState<boolean | undefined>(undefined);
  const router = useRouter();
  const {
    asPath
  } = router;
  const isAdminRoute = asPath.startsWith('/admin');
  const form = useForm({
    initialValues: {
      email: '',
      message: ''
    },
    validate: {
      // email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
      message: value => !!value ? null : 'Please enter a message'
    }
  });
  const onSubmit = async (values: {
    email?: string;
    message: string;
  }) => {
    setIsSuccess(undefined);
    try {
      const {
        email,
        message
      } = values;
      const response = await submitFeedback({
        variables: {
          email,
          message
        }
      });
      const success = response?.data?.feedback ?? false;
      setIsSuccess(success);
      if (success) {
        form.reset();
      }
    } catch {
      setIsSuccess(false);
    }
  };
  if (isAdminRoute) {
    return <></>;
  }
  const isSendable = form.isValid();
  return <>
			<Modal opened={opened} onClose={close} title="Feedback 🙏" size="lg" data-sentry-element="Modal" data-sentry-source-file="FeedbackWidget.tsx">
				<p>
					Please provide any feedback that could help make Guided{' '}
					{version === Types.VERSION.PEAKS ? 'Peaks' : 'Trekking'} more useful
					to you.
				</p>

				<form onSubmit={form.onSubmit(values => onSubmit(values))}>
					<Textarea minRows={6} withAsterisk label="Message" placeholder="your@email.com" {...form.getInputProps('message')} style={{
          marginBottom: '10px'
        }} data-sentry-element="Textarea" data-sentry-source-file="FeedbackWidget.tsx" />
					<TextInput label="Email" placeholder="your@email.com" description={'So I can tell you once the site is improved accordingly or further understand your feedback'} {...form.getInputProps('email')} data-sentry-element="TextInput" data-sentry-source-file="FeedbackWidget.tsx" />

					<br />
					{isSuccess && <Alert icon={<Check size="1rem" />} title="Feedback received" color="green">
							<p>
								Thank you for sharing, if you think of anything else feel free
								to send it.
							</p>
							{/* <p>
        If you have time,{' '}
        <a
        	href="https://forms.gle/e6YN6ZM4LVvnZaAE9"
        	target="_blank"
        	rel="noreferrer"
        >
        	answering this survey
        </a>{' '}
        would also be very helpful.
        </p> */}
						</Alert>}
					{isSuccess === false && <Alert icon={<AlertCircle size="1rem" />} title="🤦‍♂️ Something went wrong" color="red">
							message didn&apos;t send 🤷‍♂️
						</Alert>}

					<Group position="right" mt="md" data-sentry-element="Group" data-sentry-source-file="FeedbackWidget.tsx">
						<Button disabled={!isSendable || isSubmitting} type="submit" data-sentry-element="Button" data-sentry-source-file="FeedbackWidget.tsx">
							{isSubmitting ? <>
									Sending&nbsp;&nbsp;&nbsp;
									<Loader size="sm" variant="dots" color="gray" />
								</> : isSendable ? 'Send message' : 'Send'}
						</Button>
					</Group>
				</form>
			</Modal>

			<div className="bottom-corner-element right-corner">
				<Group position="center" data-sentry-element="Group" data-sentry-source-file="FeedbackWidget.tsx">
					<Button leftIcon={<Mail />} onClick={open} variant="default" radius={0} data-sentry-element="Button" data-sentry-source-file="FeedbackWidget.tsx">
						Feedback
					</Button>
				</Group>
			</div>
		</>;
};
export default FeedbackWidget;