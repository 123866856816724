import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Link from 'next/link';
import { Alert, Box, Button, Card, Divider, Grid, Group, List, Menu, Modal, Tabs, Text, ThemeIcon } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { Logout, Mountain, Pencil, PhotoUp, Trekking } from 'tabler-icons-react';
import { useRouter } from 'next/router';
import NextImage from 'next/image';
import * as Types from 'declarations';
import { isGuest as isGuestSelector, isLoginOpen as isLoginOpenSelector, isSignUpOpen as isSignUpOpenSelector, isUser as isUserSelector, isGuide as isGuideSelector, isUserAdmin as isUserAdminSelector } from 'redux/selectors';
import { VersionContext } from '../contexts/VersionContext';
import { closeSignUp, logout } from '../redux/actions';
import LoginForm from './LoginForm';
import RegisterForm from './RegisterForm';
import ResetPasswordForm from './ResetPasswordForm';
import { useIsMobile } from 'lib/useIsMobile';
import { getDefaultImage } from 'lib/helper';
import { ACCENT_COLOUR } from 'pages/_app';
const JoinCommunity = () => {
  const version = useContext(VersionContext)?.version;
  return <Text weight={500} data-sentry-element="Text" data-sentry-component="JoinCommunity" data-sentry-source-file="AccountModal.tsx">
			Join a community of{' '}
			{version === Types.VERSION.PEAKS ? 'mountaineers' : 'trekkers'}
		</Text>;
};
const AccountModal = () => {
  const [opened, {
    open,
    close
  }] = useDisclosure(false);
  const version = useContext(VersionContext).version;
  const router = useRouter();
  const isMobile = useIsMobile();
  const isGuest = useSelector(isGuestSelector);
  const isUser = useSelector(isUserSelector);
  const isGuide = useSelector(isGuideSelector);
  const isAdmin = useSelector(isUserAdminSelector);
  const isSignUpOpen = useSelector(isSignUpOpenSelector);
  const isLogInOpen = useSelector(isLoginOpenSelector);
  useEffect(() => {
    if (isSignUpOpen) {
      open();
      setActiveTab('join');
    }
  }, [isSignUpOpen]);
  useEffect(() => {
    if (isLogInOpen) {
      open();
      setActiveTab('login');
    }
  }, [isLogInOpen]);
  const [activeTab, setActiveTab] = useState<string | null>('login');
  const dispatch = useDispatch();
  const logOut = () => {
    dispatch(logout());
    router.push('/');
  };
  const closeModal = () => {
    setActiveTab('login');
    close();
    dispatch(closeSignUp());
  };
  const isForcedGuideLogin = router.asPath.includes('#open-login-guide'); // Check if the URL contains the fragment

  const iconProps = {
    size: 24,
    radius: 'xl',
    color: ACCENT_COLOUR
  };
  return <>
			<Modal opened={opened} onClose={closeModal} title={`Guided ${version === Types.VERSION.PEAKS ? 'Peaks' : 'Trekking'} - ${version === Types.VERSION.PEAKS ? 'Mountaineering' : 'Trekking'} community`} size="xl"
    // show above mobile menu
    zIndex={15000} data-sentry-element="Modal" data-sentry-source-file="AccountModal.tsx">
				{isGuest && <Box>
						{isForcedGuideLogin && <Alert color={'orange'} title="Please log in" mb="xl">
								Log in first to access your dashboard.
							</Alert>}
						<Grid>
							{!isMobile && <Grid.Col span={7}>
									<Box pr="xl">
										<Card shadow="sm" padding="lg" radius="md" withBorder>
											<Card.Section>
												<NextImage src={getDefaultImage(version)} alt={`Guided ${version === Types.VERSION.PEAKS ? 'peaks' : 'trekking'}`} height={200} width={404} />
											</Card.Section>

											<Group position="apart" mt="md" mb="xs">
												<JoinCommunity />
											</Group>

											<Text size="sm" color="dimmed">
												<List spacing="xs" size="sm" center>
													<List.Item icon={<ThemeIcon {...iconProps}>
																<Trekking size="1rem" />
															</ThemeIcon>}>
														Find affordable local guides for peaks
													</List.Item>
													<List.Item icon={<ThemeIcon {...iconProps}>
																<PhotoUp size="1rem" />
															</ThemeIcon>}>
														Contribute pictures of routes
													</List.Item>
													{/* // todo: enable these once features are ready */}
													{/* <List.Item>
              Suggest new routes
              </List.Item>
              <List.Item>
              Wishlist trips you&apos;re interested in
              </List.Item> */}
													<List.Item icon={<ThemeIcon {...iconProps}>
																<Pencil size="1rem" />
															</ThemeIcon>}>
														Edit/correct/add information for routes
													</List.Item>
													{/* // todo: ask/answer questions
              // todo: get notified about new routes 
              // todo: receive discounted trips */}
												</List>
											</Text>

											{/* <Button
            variant="light"
            color="blue"
            fullWidth
            mt="md"
            radius="md"
            // onClick={openSignUp}
            >
            [Join]
            </Button> */}
										</Card>
									</Box>
								</Grid.Col>}

							<Grid.Col span={isMobile ? 12 : 5}>
								<Box>
									{isMobile && <>
											<JoinCommunity />
											<br />
										</>}
									<Tabs variant="outline" value={activeTab} onTabChange={setActiveTab}>
										<Tabs.List>
											<Tabs.Tab value="login">Login</Tabs.Tab>
											<Tabs.Tab value="join">Join</Tabs.Tab>
										</Tabs.List>
										<Tabs.Panel value="login" pt="xs">
											<LoginForm onLogin={closeModal} />
											<Divider my="sm" />
											<Button variant={'outline'} onClick={() => setActiveTab('forgot_password')} size="xs">
												Forgot password?
											</Button>
										</Tabs.Panel>

										<Tabs.Panel value="join" pt="xs">
											<RegisterForm onRegister={closeModal} />
										</Tabs.Panel>

										<Tabs.Panel value="forgot_password" pt="xs">
											<ResetPasswordForm />
										</Tabs.Panel>
									</Tabs>
								</Box>
							</Grid.Col>
						</Grid>
					</Box>}
			</Modal>

			{isGuest && <>
					<Button onClick={open} variant="outline" fullWidth>
						Access Community
					</Button>
				</>}
			{isGuide && version === Types.VERSION.PEAKS && <>
					<Link href="/guide">
						<Button mr="sm" fullWidth>
							Guide section
						</Button>
					</Link>
				</>}
			{(isUser || isGuide) && <Menu shadow="md" width={200}>
					<Menu.Target>
						<Button variant="outline">Account&nbsp;&#x25BC;</Button>
					</Menu.Target>

					<Menu.Dropdown>
						{isGuide && version === Types.VERSION.PEAKS && <>
								<Link href="/guide">
									<Menu.Item icon={<Mountain size={14} />}>
										Guide section
									</Menu.Item>
									<Menu.Divider />
								</Link>
							</>}

						<Menu.Item onClick={() => logOut()} icon={<Logout size={14} />}>
							Log out
						</Menu.Item>
					</Menu.Dropdown>
				</Menu>}
			{isAdmin && <>
					<Button onClick={() => logOut()}
      // fullWidth
      variant="outline">
						Logout
					</Button>
				</>}
		</>;
};
export default AccountModal;