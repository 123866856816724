globalThis["__sentryRewritesTunnelPath__"] = "/monitoring";
globalThis["SENTRY_RELEASE"] = {"id":"O-DK90Kos6yZGqwuWNtHx"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import { init, replayIntegration } from '@sentry/nextjs'

if (process.env.NODE_ENV !== 'development') {
	init({
		dsn:
			'https://d920a6cbf814b04bdb81824891677d85@o4508116831633408.ingest.us.sentry.io/4508116834582528',

		// Add optional integrations for additional features
		integrations: [replayIntegration()],

		// Define how likely traces are sampled. Adjust this value in production, or use tracesSampler for greater control.
		tracesSampleRate: 1,

		// Define how likely Replay events are sampled.
		// This sets the sample rate to be 10%. You may want this to be 100% while
		// in development and sample at a lower rate in production
		replaysSessionSampleRate: 0.1,

		// Define how likely Replay events are sampled when an error occurs.
		replaysOnErrorSampleRate: 1.0,

		// Setting this option to true will print useful information to the console while you're setting up Sentry.
		debug: false,
	})
}
